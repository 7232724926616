import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { connect } from 'react-redux';
import {
  CLIENT_TRIPARTIE,
} from 'helpers/constants';
import { getToken, logout } from 'logic/actions/authActions';
import { createUser, checkEmail } from 'logic/actions/usersActions';
import { getOwnPaymentCards } from 'logic/actions/paymentCardsActions';
import CheckoutRecapDesktop from 'components/CheckoutRecapDesktop';
import CheckoutBreadcrumbBar from 'components/CheckoutBreadcrumbBar';
import Footer from 'components/Footer';

const CheckoutDesktop = ({
  transactionTemplate = {},
  transaction,
  picture,
  showCounterOfferModal,
  setShowAddressModal,
  deliveryAddress,
  deliveryMethod,
  activeKey,
  showTuto,
  currentProvider,
}) => {
  const {
    client, adUrl, allowPriceChange, clientId,
  } = transactionTemplate;

  return (
    <div className="checkout">
      <Row className="checkout__content">
        <Col lg="5">
          {client && client !== CLIENT_TRIPARTIE && (
            <CheckoutBreadcrumbBar
              showLogo
              client={client}
              adUrl={adUrl}
              clientId={clientId}
            />
          )}
          <CheckoutRecapDesktop
            transactionTemplate={transactionTemplate}
            transaction={transaction}
            picture={picture}
            showCounterOfferModal={showCounterOfferModal}
            deliveryAddress={deliveryAddress}
            setShowAddressModal={setShowAddressModal}
            deliveryMethod={deliveryMethod}
            activeKey={activeKey}
            allowPriceChange={allowPriceChange}
            currentProvider={currentProvider}
          />
        </Col>
        <Col lg="2">
          <div className="h-100 m-auto checkout__separator" />
        </Col>
        <Col lg="5">
          <h5>
            Nous sommes au regret de vous informer que la création de nouveaux comptes et l’envoi d’offres d’achat ne sont plus disponibles sur Tripartie. Pour en savoir plus sur cette décision et ses implications, veuillez consulter notre article dédié dans
            {' '}
            <a href="https://tripartie.freshdesk.com/support/solutions/articles/201000104698-arr%C3%AAt-de-tripartie">le Centre d’Aide</a>
            {' '}
            . Notre équipe reste également à votre écoute. Merci pour votre compréhension.
          </h5>
        </Col>
      </Row>
      <Footer checkout showTuto={showTuto} className="checkout__footer" />
    </div>
  );
};

const mapStateToProps = (state) => ({
  loggedIn: state.persistent.meReducer.loggedIn,
  me: state.persistent.meReducer.me,
});

const actionCreators = {
  getToken,
  createUser,
  checkEmail,
  getOwnPaymentCards,
  logout,
};

export default connect(mapStateToProps, actionCreators)(CheckoutDesktop);
