import classes from 'assets/style/signup.module.scss';
import Footer from 'components/Footer';
import LoginHeader from 'components/LoginHeader';
import { getToken } from 'logic/actions/authActions';
import { handleGreetingModal } from 'logic/actions/meActions';
import { checkEmail, createUser } from 'logic/actions/usersActions';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import queryString from 'query-string';

const Signup = ({
  loggedIn,
  location: { state: { referer = '/' } = {} } = {},
}) => {
  const { t } = useTranslation('signup');

  if (loggedIn) {
    const queryParams = queryString.parse(referer?.search);

    const externalId = queryParams['external-id'];

    if (externalId) {
      return <Redirect to={referer?.pathname || referer} />;
    }

    return <Redirect to={referer} />;
  }

  return (
    <>
      <Helmet>
        <title>{t('pageTitle')}</title>
      </Helmet>
      <div className={classes.container}>
        <div className={classes.wrapper}>
          <LoginHeader />
          <p className={classes.title}>{t('title')}</p>
        </div>
        <Footer className={classes.footer} />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  loggedIn: state.persistent.meReducer.loggedIn,
});

const actionCreators = {
  getToken,
  createUser,
  checkEmail,
  handleGreetingModal,
};

export default connect(
  mapStateToProps,
  actionCreators,
)(Signup);
