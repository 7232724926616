import React, { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { FormattedNumber } from 'react-intl';
import classNames from 'classnames';
import {
  CURRENCY_EUR,
  CLIENT_TRIPARTIE,
  DELIVERY_METHOD_REMOTE,
} from 'helpers/constants';
import Button from 'components/Button';
import CheckoutRecapMobile from 'components/CheckoutRecapMobile';
import CheckoutBreadcrumbBar from 'components/CheckoutBreadcrumbBar';
import Footer from 'components/Footer';
import GoBackCheckoutLink from 'components/GoBackCheckoutLink';
import IconArrowDown from 'assets/images/header-2-arrow.svg';
import IconBag from 'assets/images/icon-bag.svg';
import { getCancelReturnUrl } from 'helpers/transactionTemplateReturnUrl';
import 'assets/style/checkoutMobile.scss';

const CheckoutMobile = ({
  transactionTemplate = {},
  transaction,
  picture,
  showCounterOfferModal,
  deliveryAddress,
  deliveryMethod,
  showMobileRecap,
  setShowMobileRecap,
  showTuto,
  currentProvider,
}) => {
  const { t } = useTranslation('checkout');

  const [visibleDropdownBoxDetail, setVisibleDropdownBoxDetail] = useState(false);

  const setVisibleDropdownBox = useCallback(
    () => setVisibleDropdownBoxDetail((prev) => !prev),
    [],
  );

  const {
    client,
    adUrl,
    currency = CURRENCY_EUR,
    allowPriceChange,
    clientId,
    shippingFees = 0,
  } = transactionTemplate;

  const gotoClient = useCallback(() => {
    window.location = getCancelReturnUrl(adUrl);
  }, [adUrl]);

  const { buyerTotal = 0, initialSubTotal, subTotal } = transaction;
  const withCounterOffer = initialSubTotal !== undefined && initialSubTotal !== subTotal;

  const showShippingFees = deliveryMethod === DELIVERY_METHOD_REMOTE;

  const calculatedShippingFees = useMemo(
    () => (currentProvider ? currentProvider?.shippingFees : shippingFees),
    [shippingFees, currentProvider],
  );

  const displayedBuyerTotal = useMemo(
    () => (showShippingFees ? buyerTotal + calculatedShippingFees : buyerTotal),
    [showShippingFees, buyerTotal, calculatedShippingFees],
  );

  return (
    <div className="checkout">
      <div
        className={classNames('d-lg-none checkout-header-mobile', {
          'bg-white': visibleDropdownBoxDetail || showMobileRecap,
        })}
      >
        <div className="w-100">
          {client && client !== CLIENT_TRIPARTIE && (
            <CheckoutBreadcrumbBar
              showLogo={showMobileRecap}
              client={client}
              adUrl={adUrl}
              clientId={clientId}
            />
          )}
        </div>
        {!showMobileRecap && (
          <div
            className="d-flex align-items-center justify-content-end"
            onClick={setVisibleDropdownBox}
            onKeyPress={setVisibleDropdownBox}
            role="button"
            tabIndex={0}
          >
            <p className="m-0 d-flex align-items-center justify-content-end">
              {visibleDropdownBoxDetail ? (
                <span className="mr-2">{t('close')}</span>
              ) : (
                <>
                  <img src={IconBag} width="15px" alt="icon" className="mb-1" />
                  <span
                    className={`ml-2 mr-2 text-main-semi-bold ${
                      withCounterOffer && 'text-primary'
                    }`}
                  >
                    <FormattedNumber
                      value={displayedBuyerTotal / 100}
                      style="currency"
                      currency={currency}
                    />
                  </span>
                </>
              )}
              {!showMobileRecap && (
                <img
                  className={`clickable mb-1 open-menu-arrow ${visibleDropdownBoxDetail ? 'opened' : ''}`}
                  src={IconArrowDown}
                  width="10px"
                  alt="expand icon"
                />
              )}
            </p>
          </div>
        )}
        <div
          className={classNames(
            'position-fixed bg-white checkout-box-dropdown',
            {
              active: visibleDropdownBoxDetail,
            },
          )}
        >
          <CheckoutRecapMobile
            isDropdown
            transactionTemplate={transactionTemplate}
            transaction={transaction}
            picture={picture}
            deliveryAddress={deliveryAddress}
            deliveryMethod={deliveryMethod}
            displayedBuyerTotal={displayedBuyerTotal}
            currentProvider={currentProvider}
          />
          {allowPriceChange && (
            <Button
              variant="link"
              className="w-100 btn-block btn-primary"
              onClick={showCounterOfferModal}
            >
              {t('counterPrice')}
            </Button>
          )}
          {adUrl && (
            <GoBackCheckoutLink
              handleClick={gotoClient}
              text={t('goBackToClient', { client })}
            />
          )}
        </div>
      </div>

      {showMobileRecap ? (
        <div className="checkout__content">
          <CheckoutRecapMobile
            transactionTemplate={transactionTemplate}
            transaction={transaction}
            picture={picture}
            deliveryMethod={deliveryMethod}
            displayedBuyerTotal={displayedBuyerTotal}
            currentProvider={currentProvider}
          />
          <div>
            <Button
              className="btn-block"
              onClick={() => {
                setShowMobileRecap(false);
              }}
            >
              {t('continue')}
            </Button>
            {allowPriceChange && (
              <Button
                variant="outline-primary"
                className="checkout__counter-price-btn w-100 text-primary"
                textClassName="counter-offer-text"
                onClick={showCounterOfferModal}
              >
                {t('counterPrice')}
              </Button>
            )}
            {adUrl && (
              <GoBackCheckoutLink
                handleClick={gotoClient}
                text={t('goBackToClient', { client })}
              />
            )}
          </div>
        </div>
      ) : (
        <div className="checkout__content">
          <h5>
            Nous sommes au regret de vous informer que la création de nouveaux comptes et l’envoi d’offres d’achat ne
            sont plus disponibles sur Tripartie. Pour en savoir plus sur cette décision et ses implications, veuillez
            consulter notre article dédié dans le
            {' '}
            <a href="https://tripartie.freshdesk.com/support/solutions/articles/201000104698-arr%C3%AAt-de-tripartie">le Centre d’Aide</a>
            .
            Notre équipe reste également à votre écoute. Merci
            pour votre compréhension.
          </h5>
        </div>
      )}
      <Footer checkout showTuto={showTuto} className="checkout__footer" />
    </div>
  );
};

const mapStateToProps = (state) => ({
  loggedIn: state.persistent.meReducer.loggedIn,
  me: state.persistent.meReducer.me,
});

export default connect(mapStateToProps)(CheckoutMobile);
